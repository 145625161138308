import React from 'react';
import { Image, Divider, Form, Input, Button, Row, Col } from 'antd';
import * as Icon from 'react-feather';
import './profile.css';
import { useQuery } from 'react-query';
import { api_srv } from 'services';
import Loader from 'components/widgets/loader';

const Profile = () => {
	const [form] = Form.useForm();

	async function fetch_intermediary_details() {
		let resp = await (await api_srv).get_intermediary_type();
		console.log('THE PROFILE DATA', resp);
		return resp.intermediary_details.intermediary_details;
	}

	const { data, isLoading, isSuccess } = useQuery('profile details', fetch_intermediary_details);
	console.log("data", data)

	return (
		<>
			{isLoading && <Loader />}
			{isSuccess && (
				<div>
					<div className="hero__image">
						<button className="mt-4 float-right mr-3 btn btn-sm btn-outline-success text-white">
							<Icon.Camera size={14} className="mr-2" /> Change cover
						</button>
						<div className="profile__content">
							<div className="row overlap">
								<div className="col-md-4">
									<div className="card">
										<div className="card-body">
											<div className="profile__image">
												<Image src={`https://api-uat.nexus.ke${data.image_path}`} />
												<h6 className="mt-3">{data.kyc[0].intermediary_names}</h6>
											</div>
											<Divider />
											<p className="my-0 text-dark">
												<Icon.Phone size={16} />
												<span className="ml-2"> {data.contacts.map((contact) => contact)}</span>
											</p>
											<Divider className="my-3" />
											<p className="my-0 text-dark">
												<Icon.Mail size={16} />
												{/* <span className="ml-2">{data.emails.map((email) => email)}</span> */}
												<span className="ml-2">{data.kyc[0].emails}</span>
											</p>
											<Divider className="my-3" />
											<p className="my-0 text-dark">
												<Icon.MapPin size={16} />
												<span className="ml-2">{data.kyc[0].address}</span>
											</p>
										</div>
									</div>
								</div>
								<div className="col-md-8">
									<div className="card">
										<div className="card-header">
											<h6>Kyc Details</h6>
										</div>
										<div className="card-body">
											<Form form={form} layout="vertical">
												<Row gutter={16}>
													<Col span={12}>
														<Form.Item label="Full Name">
															<Input value="" placeholder={data.kyc[0].intermediary_names} />
														</Form.Item>
													</Col>
													<Col span={12}>
														<Form.Item label="Contact">
															<Input
																value=""
																placeholder={data.contacts.map((contact) => contact)}
															/>
														</Form.Item>
													</Col>
													<Col span={12}>
														<Form.Item label="ID number">
															<Input value="" placeholder={data.identification_number} />
														</Form.Item>
													</Col>
													<Col span={12}>
														<Form.Item label="KRA Pin Number">
															<Input value="" placeholder={data.kyc[0].kra_pin} />
														</Form.Item>
													</Col>
													<Col span={12}>
														<Form.Item label="Category">
															<Input value="" placeholder={data.category} />
														</Form.Item>
													</Col>
													<Col span={12}>
														<Form.Item label="IRA Code">
															<Input value="" placeholder={data.ira_code} />
														</Form.Item>
													</Col>
													<Col span={12}>
														<Form.Item label="Address">
															<Input value="" placeholder={data.kyc[0].address} />
														</Form.Item>
													</Col>
													<Col span={12}>
														<Form.Item label="Description">
															<Input.TextArea value="" placeholder={data.kyc[0].description} />
														</Form.Item>
													</Col>
												</Row>
												<Form.Item>
													<Button type="primary">Update </Button>
												</Form.Item>
											</Form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Profile;
