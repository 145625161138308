import React, { useContext, useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import {
  Divider,
  Descriptions,
  Tooltip,
  Rate,
  Empty,
  Popconfirm,
  Drawer,
  Tabs,
} from "antd";
import { IoIosAdd } from "react-icons/io";
import { FcDownload } from "react-icons/fc";
import * as Icon from "react-feather";
import { useQuery, useMutation } from "react-query";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import { MdLibraryAdd } from "react-icons/md";
import { GrChapterAdd } from "react-icons/gr";
import { AiFillEye } from "react-icons/ai";
import { FiCheckSquare } from "react-icons/fi";
import styled from "styled-components";

import styles from "./Request.module.css";
import AddRecommendation from "components/widgets/modals/AddRecommendation";
import AddBenefits from "components/widgets/modals/AddProductBenefits";
import AddRiskToRecommendation from "components/widgets/modals/AddRiskToRecommendation";
import ViewRisk from "components/widgets/modals/ViewRiskDetails";
import AddRisk from "components/widgets/modals/AddQuotationRequestRisk";
import RejectRequest from "components/widgets/modals/RejectQuotationRequest";
import { ModalContext } from "context/ModalContext";
import { DashboardContext } from "context/DashboardContext";
import {
  view_quotation_request,
  accept_quotation_request,
  list_recommendations,
  list_risks,
  remove_recommendation,
  send_quotation_request,
  remove_product_benefits,
  base_url,
  recommendation_details,
} from "services/apiSrv";
import Loader from "components/widgets/loader";
import ComponentLoader from "components/widgets/loader/ComponentLoader";
import routes from "routes";
import {
  show_toast,
  convertCurrency,
  formatDate,
  fileExtension,
} from "utils/helpers";
import PdfIcon from "assets/images/pdf.png";
import DocIcon from "assets/images/doc-icon.png";
import FileIcon from "assets/images/file-icon.png";
import ExcelIcon from "assets/images/Excel-Icon.png";

const settings = {
  dots: true,
  infinite: false,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: false,
        nextArrow: false,
      },
    },
  ],
};

const Document = styled.div`
  margin-bottom: 40px;
  display: grid;
  grid-gap: 50px;
`;

const DocumentRow = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
`;
const DocumentTitle = styled.a`
  line-height: 1.5em;
  font-size: 15px;
  margin-top: 1rem;
`;
const DocumentLogo = styled.img`
  max-width: 70px;
`;

const DocumentItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;
const DocumentLogoContainer = styled.div`
  display:none ;
  &:hover {
    display:block
    background-color: black;
    width: 100px;
    height: 100px;
    margin: 10px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.3s ease-in-out;
  }
`;
const DeleteDocument = styled.div`
  ${DocumentLogoContainer}
  &:hover {
  }
`;

export default function QuotationRequestDetails() {
  const { setModalShow, modalShow, modalClose } = useContext(ModalContext);
  const { prodId, setProductId, ref, setRef } = useContext(DashboardContext);
  const history = useHistory();
  const { id } = useParams();
  const { TabPane } = Tabs;
  const [showRecommendationDrawer, setShowRecommendationDrawer] =
    useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [recommendationDetails, setRecommendationDetails] = useState("");
  const [riskref, setRiskref] = useState("");

  async function fetch_request_details() {
    let resp = await view_quotation_request(id);
    console.log("quot det", resp);
    return resp.request;
  }
  async function fetch_risks() {
    let resp = await list_risks(id);
    console.log("risk det", resp);
    return resp.risks;
  }

  const { isLoading, isSuccess, data, refetch } = useQuery(
    "requestDetails",
    fetch_request_details
  );

  const systemRef = data?.system_quotation_request_ref;

  const {
    isLoading: isLoadingRecommendations,
    isSuccess: isSuccessRecommendations,
    data: recommendations,
    refetch: refetchRecommendations,
  } = useQuery("recommendations", fetch_recommendations, {
    enabled: !!systemRef,
  });

  console.log("recommendations", recommendations)
  const {
    isLoading: isLoadingRisks,
    isSuccess: isSuccessRisks,
    data: risks,
    refetch: refetchRisks,
  } = useQuery("risks", fetch_risks);

  async function fetch_recommendations() {
    let resp = await list_recommendations(systemRef);
    console.log("RECOMME", resp);
    return resp.recommendations;
  }

  const acceptRequest = useMutation(accept_quotation_request, {
    onSuccess: (data) => {
      refetch();
      show_toast(
        "Request has been accepted, you can now add recommendations",
        "success"
      );
    },
    onError: async (error) => {},
  });

  const sendRequest = useMutation(send_quotation_request, {
    onSuccess: (data) => {
      history.push(routes.quotationRequests);
      show_toast("Request successfully sent to client", "success");
    },
    onError: async (error) => {},
  });

  async function confirm(id) {
    await remove_recommendation(id);
    refetchRecommendations();
  }
  async function removeBenefit(id, product_benefit_id) {
    await remove_product_benefits(id, product_benefit_id);
    refetchRecommendations();
  }

  const onClose = () => {
    setShowRecommendationDrawer(false);
  };

  const showDrawer = async (id) => {
    setLoadingDetails(true);
    try {
      setShowRecommendationDrawer(true);
      let resp = await recommendation_details(id);
      console.log("RECOMME DETAILS", resp);

      setRecommendationDetails(resp.recommendation);
      setLoadingDetails(false);
    } catch (err) {
      setLoadingDetails(false);
    }
  };

  const checkExtension = (file_path) => {
    let ext = fileExtension(file_path);
    switch (ext) {
      case "pdf":
        return PdfIcon;
      case "xls":
        return ExcelIcon;
      case "xlsx":
        return ExcelIcon;
      case "doc":
        return DocIcon;
      case "docx":
        return DocIcon;
      default:
        return FileIcon;
    }
  };
  return (
    <>
      {isLoading && isLoadingRecommendations && isLoadingRisks && <Loader />}
      {isSuccess && isSuccessRecommendations && isSuccessRisks && (
        <div id="main-wrapper">
          <div className="pageheader pd-t-25 ">
            <div className="pd-t-5"></div>
            <div className="breadcrumb pd-0 mg-0">
              <a className="breadcrumb-item" href="index.html">
                <i className="icon ion-ios-home-outline" /> Home
              </a>
              <a className="breadcrumb-item active" href>
                Claims
              </a>
              <a className="breadcrumb-item active" href>
                View
              </a>
            </div>
            <h1 className="pd-0 my-md-3 float-left tx-20">
              Quotation Request{" "}
              <span className="text-mint">
                {data.system_quotation_request_ref}
              </span>{" "}
            </h1>
            <div className="dropdown clearfix">
              <button
                className="float-right mr-4 btn btn-primary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Actions
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <li>
                  <a
                    onClick={() =>
                      acceptRequest.mutate(data.system_quotation_request_ref)
                    }
                    className="dropdown-item tx-12-force"
                    href
                    disabled={
                      data.quotation_request_queue.queue_status.toLowerCase() ===
                      "active"
                        ? true
                        : false
                    }
                  >
                    <Icon.CheckSquare
                      size={16}
                      className="mr-1"
                      color={
                        data.quotation_request_queue.queue_status.toLowerCase() ===
                        "active"
                          ? "grey"
                          : "green"
                      }
                    />
                    Accept Request
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item tx-12-force"
                    href
                    onClick={() => setModalShow("rejectRequest")}
                  >
                    <Icon.XSquare size={16} className="mr-1" color="red" />{" "}
                    Reject Request
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item tx-12-force"
                    href
                    disabled={
                      data.quotation_request_queue.queue_status === "pending"
                        ? true
                        : false
                    }
                    onClick={() =>
                      sendRequest.mutate(data.system_quotation_request_ref)
                    }
                  >
                    <Icon.Send
                      size={16}
                      className="mr-1"
                      color={
                        data.quotation_request_queue.queue_status === "pending"
                          ? "grey"
                          : "green"
                      }
                    />{" "}
                    Send to Client
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {riskref && (
            <ViewRisk
              risk_ref={riskref}
              onHide={modalClose}
              show={modalShow === "risk" ? true : false}
            />
          )}
          <RejectRequest
            show={modalShow === "rejectRequest" ? true : false}
            onHide={modalClose}
            systemRef={data.system_quotation_request_ref}
          />
          <AddRisk
            show={modalShow === "riskModal" ? true : false}
            onHide={modalClose}
            systemRef={data.system_quotation_request_ref}
            clientId={data.client_ref}
            refetchRisks={refetchRisks}
          />
          <AddRecommendation
            show={modalShow === "recommendModal" ? true : false}
            onHide={modalClose}
            systemRef={id}
            quotationRequestRef={data.system_quotation_request_ref}
          />
          {ref && (
            <>
              <AddBenefits
                show={modalShow === "benefits" ? true : false}
                onHide={modalClose}
                productid={prodId}
                requestref={ref}
              />
              <AddRiskToRecommendation
                show={modalShow === "risk_recommendation" ? true : false}
                onHide={modalClose}
                requestref={ref}
                id={id}
              />
            </>
          )}
          <div className="card mb-md-5 mb-3 mt-3 mt-md-0">
            <div className="card-header font-weight-bold bg-light">
              <span>Details</span>
              {data.request_type === "download" && (
                <a href className="ml-md-auto text-primary">
                  <FcDownload size={20} className="mr-md-1" />
                  Download
                </a>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                {/* <div className="col-md-2 border-md-right  ">
                  <div className="card">
                    <div className="card-body">
                      <img
                        src="https://bootdey.com/img/Content/avatar/avatar6.png"
                        alt="user"
                        className={styles.userImage}
                      />
                    </div>
                    <div className="card-footer ">
                      <p className="mx-auto">{data.names}</p>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-9 offset-md-1">
                  <Descriptions
                    title=""
                    layout="vertical"
                    size="small"
                    className="mt-3"
                  >
                    <Descriptions.Item
                      label={
                        <span className="font-weight-bold">Request Date</span>
                      }
                    >
                      {formatDate(data.request_date)}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <span className="font-weight-bold">
                          Request Reference
                        </span>
                      }
                    >
                      {data.system_quotation_request_ref}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <span className="font-weight-bold">Request Type</span>
                      }
                    >
                      {data.request_type}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <span className="font-weight-bold">Created Date</span>
                      }
                    >
                      {formatDate(data.created_date)}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <span className="font-weight-bold">Status Mesaage</span>
                      }
                    >
                      {data.quotation_request_queue.status_message}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<span className="font-weight-bold">Remarks</span>}
                    >
                      {data.remarks}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-md-5 mb-3">
            <div className="card-header bg-light font-weight-font-weight-bold">
              <span className="float-left">Quotation Risk</span>
              <a
                onClick={() => setModalShow("riskModal")}
                href
                className={`ml-md-auto  ${
                  data.quotation_request_queue.queue_status === "pending"
                    ? "text-secondary"
                    : "text-primary"
                }`}
                disabled={
                  data.quotation_request_queue.queue_status.toLowerCase() ===
                  "pending"
                    ? true
                    : false
                }
              >
                <IoIosAdd size={24} className="" />
                Add a risk
              </a>
            </div>
            <div className="card-body">
              {data.request_type.toLowerCase() === "upload" && (
                <Document>
                  <DocumentRow>
                    <DocumentItem>
                      <>
                        <DocumentLogo
                          src={checkExtension(data.upload_path)}
                          alt="document"
                        />
                        <DocumentLogoContainer>
                          <DeleteDocument>Delete</DeleteDocument>
                        </DocumentLogoContainer>
                      </>
                      <DocumentTitle
                        href={base_url + "/" + data.upload_path}
                        // rel="noopener noreferrer"
                        target="_blank"
                      >
                        Preview
                      </DocumentTitle>
                    </DocumentItem>
                  </DocumentRow>
                </Document>
              )}
              {data.request_type.toLowerCase() === "form" &&
              risks.length === 0 ? (
                <Empty description="No risks added to this request" />
              ) : (
                <Slider {...settings}>
                  {risks.map((risk) => {
                    return (
                      <section
                        className={styles.cards}
                        onClick={() => {
                          setRiskref(risk.risk_system_ref);
                          setModalShow("risk");
                        }}
                      >
                        <article className={styles.card}>
                          <div className={styles.card__info}>
                            Risk Reference:
                            <span className={styles.card__category}>
                              {" "}
                              {risk.risk_reference}
                            </span>
                            <div>
                              Sum Assured:
                              <span
                                className={styles.card__title}
                              >{`${convertCurrency(risk.sum_assured)}`}</span>
                            </div>
                            {/* <span className={styles.card__by}>
														<AiOutlineRightCircle />{' '}
														<a
															onClick={() => setModalShow('risk')}
															href
															className={styles.card__author}
															title=""
														>
															View more
														</a>
													</span> */}
                          </div>
                        </article>
                      </section>
                    );
                  })}
                </Slider>
              )}
            </div>
          </div>
          <div className="card">
            <div className="card-header bg-light font-weight-bold">
              <span className="float-left">Recommendations</span>
              <a
                href
                className={`ml-md-auto  ${
                  data.quotation_request_queue.queue_status === "pending"
                    ? "text-secondary"
                    : "text-primary"
                }`}
                onClick={() => setModalShow("recommendModal")}
                disabled={
                  data.quotation_request_queue.queue_status.toLowerCase() ===
                  "pending"
                    ? true
                    : false
                }
              >
                <IoIosAdd size={24} className="" />
                Recommend
              </a>
            </div>
            {recommendations.length === 0 && (
              <Empty
                className="mb-5"
                description="No recommenadation added for this request"
              />
            )}
            {recommendations.map((recommendation) => (
              <div className="card-body">
                <Drawer
                  title="Recommendation Details"
                  placement="right"
                  closable={true}
                  width="50%"
                  destroyOnClose={true}
                  onClose={onClose}
                  visible={showRecommendationDrawer}
                >
                  {loadingDetails && <ComponentLoader />}
                  {!loadingDetails && recommendationDetails !== "" && (
                    <>
                      <div className={styles.recommendationDetCard}>
                        <img
                          src={
                            base_url +
                            recommendationDetails.product_details[0].logo
                          }
                          alt="product logo"
                        />
                        <div>
                          <h5>
                            {
                              recommendationDetails.product_details[0]
                                ?.product_name
                            }
                          </h5>
                          <Rate
                            disabled
                            allowHalf
                            value={Number(
                              recommendationDetails.recommendation_level
                            )}
                          />
                        </div>
                      </div>
                      <Tabs defaultActiveKey="1">
                        <TabPane tab="Benefits" key="1">
                          <Tooltip title="Attach Benefits">
                            <div className="clearfix">
                              <a
                                onClick={() => {
                                  setRef(
                                    recommendation.system_quotation_request_recommendation_ref
                                  );
                                  setTimeout(setModalShow("benefits"), 3000);
                                }}
                                href
                                className="float-right mr-3"
                              >
                                <MdLibraryAdd color="green" size={20} />
                              </a>
                            </div>
                          </Tooltip>
                          {recommendationDetails.benefit_details.length ===
                          0 ? (
                            <Empty message="No benefits are available" />
                          ) : (
                            <ul className={styles.benefits_list}>
                              {recommendationDetails.benefit_details.map(
                                (benefit, i) => (
                                  <li key={i} className="font-weight-bold">
                                    <FiCheckSquare
                                      color="green"
                                      className="mr-1"
                                    />
                                    {benefit.name}
                                    <i className="font-weight-normal">
                                      ~{benefit.description}
                                    </i>
                                  </li>
                                )
                              )}
                            </ul>
                          )}
                        </TabPane>
                        <TabPane tab="Attached Risks" key="2">
                          <Tooltip title="Add Risk to this recommendation">
                            <div className="clearfix">
                              <a
                                onClick={() => {
                                  setProductId(recommendation.product_id);
                                  setRef(
                                    recommendation.system_quotation_request_recommendation_ref
                                  );
                                  setTimeout(
                                    setModalShow("risk_recommendation"),
                                    3000
                                  );
                                }}
                                href
                                className="float-right mr-3"
                              >
                                <GrChapterAdd color="#001f5f" size={20} />
                              </a>
                            </div>
                          </Tooltip>
                          {recommendationDetails.risks.length === 0 ? (
                            <Empty message="No risks are attached to this recommendation" />
                          ) : (
                            <ul>
                              {recommendationDetails.risks.map((risk) => (
                                <li key={risk}>
                                  <Icon.Check color="green" size={12} />
                                  <i className="pl-2">{risk}</i>
                                </li>
                              ))}
                            </ul>
                          )}
                        </TabPane>
                      </Tabs>
                    </>
                  )}
                </Drawer>

                <div>
                  <Popconfirm
                    title="Are you sure to remove this recommendation?"
                    onConfirm={() =>
                      confirm(
                        recommendation.system_quotation_request_recommendation_ref
                      )
                    }
                    okText="Yes"
                    cancelText="No"
                  >
                    <a href className="float-right">
                      <Icon.Trash2 color="red" size={20} />
                    </a>
                  </Popconfirm>

                  <Tooltip title="View More">
                    <a
                      onClick={() => {
                        showDrawer(
                          recommendation.system_quotation_request_recommendation_ref
                        );
                        setProductId(recommendation.product_id);
                        setRef(
                          recommendation.system_quotation_request_recommendation_ref
                        );
                      }}
                      href
                      className="float-right mr-3"
                    >
                      <AiFillEye color="green" size={20} />
                    </a>
                  </Tooltip>

                  <div className="row">
                    <div className="col-12 col-md-3 col-lg-3 d-md-flex  justify-content-center">
                      <img
                        alt="productImg"
                        style={{ width: "100%", maxHeight: "150px" }}
                        src={base_url + recommendation.product?.logo}
                      />
                    </div>
                    <div className="col-12 col-md-4 col-lg-4 ">
                      <h6>{recommendation.product?.product_name}</h6>
                      <small>
                        <span className="d-block">Recommendation Level</span>

                        <Rate
                          disabled
                          allowHalf
                          value={Number(recommendation.recommendation_level)}
                        />
                      </small>
                    </div>

                    <div className="col-12 col-md-5 col-lg-5">
                      <h6 className="">Premium Amount</h6>
                      <h3 className="text-success">
                        {convertCurrency(recommendation.premium_amount)}
                      </h3>

                      <p className="text-info ">
                        <Tooltip
                          title={`Special Code ${recommendation.special_code} Applied`}
                        >
                          <Icon.Tag size={14} className="mr-1" />{" "}
                          {`Discount: 
                          ${convertCurrency(recommendation.discount_value)}`}
                        </Tooltip>
                      </p>
                      <h6>Remarks</h6>
                      <p>{recommendation.recommendation_remarks}</p>
                    </div>
                  </div>

                  <Divider />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
