import React, { useContext, useRef, useState } from 'react';
import Navbar from 'components/dashboard-nav';
import Sidebar from 'components/dashboard-sidebar';
import FullCalendar, { formatDate } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { ModalContext } from 'context/ModalContext';
import Appointment from 'components/widgets/modals/AddAppointment';
import { fetch_appointment_details } from 'services/apiSrv';
import { useQuery } from 'react-query';
import ViewAppointment from 'components/widgets/modals/ViewAppointmentDetails';
export default function Appointments({
	handleWeekendsToggle,
	handleDateSelect,
	handleEventClick,
	addEvent,
	weekendsVisible,
	state,
	appointmentId,
	handleRemove,
	appointmentShow,
	modalAppointmentsClose,
	details,
	detailsLoading,
	successDetails,
	errorDetails,
}) {
	const { modalShow, modalClose } = useContext(ModalContext);

	function renderEventContent(eventInfo) {
		// console.log('the event info', eventInfo);
		return (
			<div>
				{' '}
				{eventInfo.event.status === 'Active' ? <i>{eventInfo.event.title}</i> : <i>{eventInfo.event.title}</i>}
			</div>
		);
	}

	function renderSidebarEvent(event) {
		return (
			<li key={event.id}>
				{event.status === 'Active' ? (
					<>
						{' '}
						<b>
							{formatDate(event.start, {
								year: 'numeric',
								month: 'short',
								day: 'numeric',
							})}
						</b>
						<i>{event.title}</i>
					</>
				) : (
					<del>
						<b>
							{formatDate(event.start, {
								year: 'numeric',
								month: 'short',
								day: 'numeric',
							})}
						</b>
						<i>{event.title}</i>
					</del>
				)}
			</li>
		);
	}

	return (
		<>
			<Appointment show={modalShow} onHide={modalClose} />
			<ViewAppointment
				show={appointmentShow}
				onHide={modalAppointmentsClose}
				data={details}
				isLoading={detailsLoading}
				isSuccess={successDetails}
				isError={errorDetails}
			/>
			<div className="demo-app">
				<div className="demo-app-sidebar">
					<div className="demo-app-sidebar-section pb-0">
						<label>
							<input type="checkbox" checked={weekendsVisible} onChange={handleWeekendsToggle}></input>
							<span className="pl-2">Toggle Weekends</span>
						</label>
					</div>
					<div className="demo-app-sidebar-section">
						<h6>All Appointments({state.length})</h6>
						<ol>{state.map(renderSidebarEvent)}</ol>
					</div>
				</div>
				<div className="demo-app-main">
					<FullCalendar
						plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
						headerToolbar={{
							left: 'prev,next today',
							center: 'title',
							right: 'dayGridMonth,timeGridWeek,timeGridDay',
						}}
						initialView="dayGridMonth"
						editable={true}
						selectable={true}
						selectMirror={true}
						dayMaxEvents={true}
						weekends={weekendsVisible}
						events={state}
						select={handleDateSelect}
						eventContent={renderEventContent}
						eventClick={handleEventClick}
						eventAdd={addEvent}
						eventRemove={handleRemove}

						// called after events are initialized/added/changed/removed
						/* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
					/>
				</div>
			</div>
		</>
	);
}
