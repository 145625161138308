import React, { useContext, useEffect } from 'react';
import AppointmentForm from './Appointment_Form';
import { ModalContext } from 'context/ModalContext';
import { AlertsContext } from 'context/AlertsContext';
import { AuthContext } from 'context/AuthContext';
import { DashboardContext } from 'context/DashboardContext';
import { createEventId } from 'utils/appointment-utils';
import { api_srv } from 'services';

const AppointmentFormContainer = () => {
	const { modalClose } = useContext(ModalContext);
	const { setShowAlert } = useContext(AlertsContext);
	const { alert, setAlert } = useContext(AuthContext);
	const {
		appointment,
		setAppointment,
		currentEvents,
		setCurrentEvents,
		selectedObject,
		myUnderwriters,
		setMyUnderwriters,
	} = useContext(DashboardContext);


	function onTimeChange(time, timeString) {
		setAppointment({ ...appointment, time: time });
	}

	async function get_my_underwriters() {
		setMyUnderwriters({ ...myUnderwriters, loading: true });
		try {
			let resp = await (await api_srv).fetch_my_underwriters();
			setMyUnderwriters({ ...myUnderwriters, data: resp.underwritters, loading: false });
		} catch (err) {
			await err;
			setMyUnderwriters({ ...myUnderwriters, loading: false });
		}
	}

	// useEffect(() => {
	// 	get_my_underwriters();
	// }, []);

	function handleAppointmentChange(evt) {
		const { value, name } = evt.target;
		setAppointment({
			...appointment,
			[name]: value,
		});
	}

	const handleUnderwritersListChange = (selected) => {
		setAppointment({ ...appointment, underwriter: selected });
		// setSelected(selected);
	};

	async function setEvents() {
		setAppointment({ ...appointment, loading: true });

		try {
			setCurrentEvents([...currentEvents, appointment]);
			// addNewEvent()
			let title = appointment.title;
			let calendarApi = selectedObject.view.calendar;

			calendarApi.unselect(); // clear date selection

			if (title) {
				let resp = await (await api_srv).create_an_appointment(appointment);
				console.log("resp", resp)
				console.log('the details sent', appointment);
				console.log('the response from appointmnet', resp);

				calendarApi.addEvent({
					id: createEventId(),
					title,
					start: selectedObject.startStr,
					end: selectedObject.endStr,
					allDay: selectedObject.allDay,
				});
			}

			setAppointment({ ...appointment, loading: false });

			setAppointment({
				title: '',
				name: '',
				phone: '',
				description: '',
				location: '',
				underwriter: '',
				time: '',
			});

			modalClose();
		} catch (err) {
			let error = await err;
			console.log('error adding appointment', error);
			setAppointment({ ...appointment, loading: false });
			setAlert({ ...alert, message: error.message, variant: 'danger' });
			setShowAlert(true);
		}
	}

	return (
		<AppointmentForm
			currentEvents={currentEvents}
			setEvents={setEvents}
			onTimeChange={onTimeChange}
			handleChange={handleAppointmentChange}
			// handleUnderwritersListChange={handleUnderwritersListChange}
		/>
	);
};

export default AppointmentFormContainer;
