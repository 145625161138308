import React, { useContext } from "react";

import { useQuery } from "react-query";
import { Empty } from "antd";

import image from "assets/images/cic-lady-auto.jpg";
import Loader from "components/widgets/loader/ComponentLoader";
import { get_subclass_products, base_url } from "services/apiSrv";
import { DashboardContext } from "context/DashboardContext";

export default function SelectProduct() {
  // Access Context

  const { recommendation, setRecommendation } = useContext(DashboardContext);

  async function fetch_subclass_product() {
    //console.log("THE RECOMMENDATION", recommendation);
    let resp = await get_subclass_products(recommendation.insuranceClass);
   // console.log("HERE HERE", resp);
    return resp.products;
  }

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setRecommendation({ ...recommendation, product_id: e.target.value });
  };
  const { isLoading, isSuccess, data } = useQuery(
    "products",
    fetch_subclass_product
  );

  return (
    <div>
      {isLoading && <Loader />}
      {isSuccess && (
        <div className="row active-with-click">
          {data.length === 0 && (
            <div className="d-flex justify-content-center align-items-center">
              <Empty description="No products have been set up for this insurance class" />
            </div>
          )}
          {data.map((product) => (
            <div className="col-md-4 col-sm-6 col-xs-12">
              <article id="id" className="material-card Red">
                <h2>
                  <span className="d-block">{product.product_name}</span>
                  <strong className="d-block">
                    {/* <i id="icon" className="fa fa-fw fa-magic" /> */}
                    {product.class_code}
                  </strong>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="product"
                      id={product.product_id}
                      value={product.product_id}
                      style={{ opacity: 1 }}
                      onChange={onChange}
                    />
                    <label
                      className="form-check-label text-dark"
                      htmlFor={product.product_id}
                    >
                      Choose this Product
                    </label>
                  </div>
                </h2>

                <div className="mc-content">
                  <div className="img-container">
                    <img
                      className="img-responsive"
                      src={base_url + product.logo}
                      alt="product"
                    />
                  </div>
                  <div className="mc-description">{product.description}</div>
                </div>
                {/* <a href onClick={()=>flipProductCard(1)} className="mc-btn-action"> */}
                <a href className="mc-btn-action">
                  <i id="icon" className="fa fa-info" />
                </a>
              </article>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
