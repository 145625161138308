import { handle_api_error } from "./errorHandler";
import axios from "axios";




const location=window.location.hostname
let apiEnv=location.split(".")[0];
console.log(" ==== api env ==== ",apiEnv)

  if(apiEnv == 'localhost') {
    apiEnv='prod'; // handle for dec
  }
  if(apiEnv == 'agency') {
    apiEnv='prod'; // handle for production
  }
  //  if(apiEnv.length =1) {
  //   apiEnv='demo';
  //  }
  //  else{
  //   apiEnv=apiEnv[1]
  //   if(apiEnv === 'localhost') {
  //     apiEnv='demo'; // handle for dec
  //   }
  //   if(apiEnv === 'nexus') {
  //     apiEnv='prod'; // handle for production
  //   }
  //  }
  

   console.log("env", apiEnv)



//let endpoint = `${domain}/api/web/v1/admin`;
//let api_url = "https://api-uat.nexus.ke/api/web/v1/serviceprovider";
let api_url = `https://api.${apiEnv}.nexus.ke/v1/api/web/v1/intermediary`;
let base_url = `https://api.${apiEnv}.nexus.ke/v1/api/web/v1/intermediary`;
let upload_url = `https://api.${apiEnv}.nexus.ke/v1/api/upload/v1/client`;
// let upload_url = 'https://api.dev.nexus.ke/api/upload/v1/client`;
let image_url = `https://api.${apiEnv}.nexus.ke/`;

function register({ email, category, ira_code }) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
        //  timeout:15000
      };

      const body = {
        resource: "registration",
        action: "add",
        data: {
          ira_code: ira_code,
          category: category,
          email: email,
        },
      };

      localStorage.setItem("REG_EMAIL", email);
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function verify(token) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
        //  timeout:15000
      };

      const body = {
        resource: "registration",
        action: "validation",
        data: {
          email_token: token,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function login({ username, password }) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
        //  timeout:15000
      };
      sessionStorage.clear();
      const body = {
        resource: "login",
        action: "login",
        data: {
          username: username,
          password: password,
          device_details: {
            device_type: "CHROME",
            device_category: "WEB",
            device_version: "V1",
            device_name: "CHROME",
            device_uuid: "1223455",
          },
        },
      };
      let response = await call_api(body, config);
      sessionStorage.setItem("token", response.token);
     // console.log('tk',sessionStorage.getItem("token"));

      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function submitCredentials(registration_setup_id, names, credentials) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
        //  timeout:15000
      };
      const body = {
        resource: "registration",
        action: "credential",
        data: {
          registration_setup_id: registration_setup_id,
          tnc: credentials.tnc,
          names: names,
          contact: credentials.contact,
          employee_ref: credentials.employee_ref,
          password: credentials.password,
          username: credentials.username,
        },
      };
      console.log("the body of submiit is", body);
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function check_kyc() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "intermediary",
        action: "details",
        data: {},
      };
      let response = await call_api(body, config);
      console.log("checking kyc response suc", response)
      return resolve(response);
    } catch (err) {
      console.log("checking kyc response er", err)
      return reject(err);
    }
  });
}

function verify_email(token) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
        // timeout:15000
      };
      const body = {
        resource: "registration",
        action: "validation",
        data: {
          email_token: token,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function reset(request_id, password) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
        // timeout:15000
      };
      const body = {
        resource: "forgot_password",
        action: "change",
        data: {
          password_change_request_id: request_id,
          password: password,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function get_intermediary_type() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "intermediary",
        action: "details",
        data: {},
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function get_requests() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "profile",
        action: "requests",
        data: {},
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function forgot_password({ username }) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
        //  timeout:15000
      };
      sessionStorage.clear();
      const body = {
        resource: "forgot_password",
        action: "request",
        data: {
          username: username,
        },
      };
      let response = await call_api(body, config);
      sessionStorage.setItem("token", response.token);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
// **************************Quotation Requests

function get_quotation_requests() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "quotation_request",
        action: "my_quotation_requests",
        data: {},
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function view_quotation_request(system_quotation_request_ref) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "quotation_request",
        action: "quotation_request_details",
        data: {
          system_quotation_request_ref: `quo-req/${system_quotation_request_ref}`,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function accept_quotation_request(system_quotation_request_ref) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "quotation_request",
        action: "accept",
        data: {
          system_quotation_request_ref: system_quotation_request_ref,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function reject_quotation_request(values) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "quotation_request",
        action: "reject",
        data: {
          system_quotation_request_ref: values.system_quotation_request_ref,
          rejection_remarks: values.rejection_remarks,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function fetch_risk_form(insurance_class_code) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "quotation_request",
        action: "quotation_form",
        data: {
          insurance_class_code: insurance_class_code,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function post_risk_form({ system_quotation_request_ref, client_id, riskForm }) {
  return new Promise(async function (resolve, reject) {
    try {
      let riskValue = [];
      Object.entries(riskForm).forEach(([key, value]) => {
        let obj = {
          name: key,
          value: value,
        };
        riskValue.push(obj);
      });

      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "quotation_request",
        action: "add_risk",
        data: {
          system_quotation_request_ref: system_quotation_request_ref,
          client_id: client_id,
          risk_form: riskValue,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function list_recommendations(system_quotation_request_ref) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "quotation_request",
        action: "quotation_reccomendation",
        data: {
          system_quotation_request_ref: system_quotation_request_ref,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function add_recommendations({
  product_id,
  quotation_req_ref,
  recommendation_remarks,
  recommendation_level,
  special_code,
  risk,
}) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "quotation_request",
        action: "create_recommendation",
        data: {
          product_id: product_id,
          quotation_req_ref: quotation_req_ref,
          recommendation_remarks: recommendation_remarks,
          recommendation_level: recommendation_level,
          special_code: special_code,
          risks: risk,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function recommendation_details(system_quotation_request_recommendation_ref) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "quotation_request",
        action: "get_recommendation",
        data: {
          system_quotation_request_recommendation_ref:
            system_quotation_request_recommendation_ref,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function remove_recommendation(system_quotation_request) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "quotation_request",
        action: "remove_recommendation",
        data: {
          system_quotation_request_recommendation_ref: system_quotation_request,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function list_risks(system_quotation_request_ref) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "quotation_request",
        action: "fetch_risks",
        data: {
          system_quotation_request_ref: `quo-req/${system_quotation_request_ref}`,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function risk_details(risk_system_ref) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "quotation_request",
        action: "get_risk_details_system_ref",
        data: {
          risk_system_ref: risk_system_ref,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function list_special_codes() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "tokens",
        action: "list",
        data: {},
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function list_product_benefits(product_id) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "product",
        action: "benefits",
        data: {
          product_id: product_id,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function add_product_benefits({ ref, benefits }) {
  return new Promise(async function (resolve, reject) {
    try {
      benefits.map(async (benefit) => {
        let config = {
          headers: {
            "Content-Type": "application/json",
            token: sessionStorage.getItem("token"),
          },
          // timeout:15000
        };
        const body = {
          resource: "quotation_request",
          action: "add_benefit",
          data: {
            system_quotation_request_recommendation_ref: ref,
            product_benefit_id: benefit.value,
            free_limit: benefit.name,
          },
        };
        let response = await call_api(body, config);
        return resolve(response);
      });
    } catch (err) {
      return reject(err);
    }
  });
}
function add_risk_to_recommendation({ ref, risk_ref }) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "quotation_request",
        action: "add_risk_recommendation",
        data: {
          system_quotation_request_recommendation_ref: ref,
          risk_ref: risk_ref,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function remove_product_benefits(ref, product_benefit_id) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "quotation_request",
        action: "remove_benefit",
        data: {
          system_quotation_request_recommendation_ref: ref,
          product_benefit_id: product_benefit_id,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function send_quotation_request(system_quotation_request_ref) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "quotation_request",
        action: "send_to_client",
        data: {
          system_quotation_request_ref: system_quotation_request_ref,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

// End of quotation requests

function get_policies() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "policies",
        action: "list",
        data: {},
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function upload_individual_agent_kyc_details(individualInfo) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "profile",
        action: "update_kyc",
        data: {
          update: {
            // category: category,
            emails: individualInfo.email,
            description: individualInfo.description,
            intermediary_names:
              individualInfo.intermediary_fname +
              " " +
              individualInfo.intermediary_lname,
            address: `${
              individualInfo.address + "-" + individualInfo.postal_code
            }`,
            contacts: individualInfo.contact,
            kra_pin: individualInfo.kra_pin,
            identification_number: individualInfo.id_number,
          },
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function upload_company_agent_kyc_details(companyInfo) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "profile",
        action: "update_kyc",
        data: {
          update: {
            // category: category,
            emails: companyInfo.email,
            description: companyInfo.description,
            intermediary_names: companyInfo.intermediary_names,
            address: `${companyInfo.address + "-" + companyInfo.postal_code}`,
            contacts: companyInfo.contact,
            kra_pin: companyInfo.kra_pin,
          },
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

async function upload_directors(directors) {
  return new Promise(async function (resolve, reject) {
    try {
      directors.map(async (director) => {
        let config = {
          headers: {
            "Content-Type": "application/json",
            token: sessionStorage.getItem("token"),
          },
          // timeout:15000
        };
        let body = {
          resource: "profile",
          action: "add_director",
          data: {
            director: {
              names: director.name,
              email: director.email,
              title: director.title,
              type: director.type,
              residency: director.residency,
              contact: director.contact,
            },
          },
        };

        let response = await call_api(body, config);
        console.log("success uploading partners", response);
        return resolve(response);
      });
    } catch (err) {
      return reject(err);
    }
  });
}

const upload_company_logo = async (info) => {
  return new Promise(async function (resolve, reject) {
    const fmData = new FormData();
    try {
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      let response = await call_api(fmData, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
};

function get_dashboard_summary() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      let body = {
        resource: "profile",
        action: "dashboard",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function get_professions() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      let body = {
        resource: "profile",
        action: "my_proffesion",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function get_professions_list() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      let body = {
        resource: "insurance",
        action: "subclass",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function update_professions(professions) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      let body = {
        resource: "profile",
        action: "update_profession",
        data: {
          profession: professions,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function set_location(lat, lng) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      let body = {
        resource: "profile",
        action: "update_location",
        data: {
          coordinates: [lat, lng],
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function get_underwriters_requests() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      let body = {
        resource: "underwritter",
        action: "active",
        data: {},
      };
      let response = await call_api(body, config);
      //console.log("u res", response)
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function request_underwriter({ underwriter_name, remarks }) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      let body = {
        resource: "underwritter",
        action: "request",
        data: {
          underwritter_code: underwriter_name.value,
          remarks: remarks.value,
        },
      };
      let response = await call_api(body, config);
      console.log("Res",response)
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function get_subclass() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "subclass",
        action: "subclass",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function get_subclass_products(code) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };

      const body = {
        resource: "subclass",
        action: "sub_class_code_products",
        data: {
          sub_class_code: code,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function get_product_summary(id) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };

      const body = {
        resource: "product",
        action: "summary",
        data: {
          product_id: id,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function get_all_quotations() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "quotations",
        action: "list",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function view_a_quotation(quotation_id) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "quotations",
        action: "details",
        data: {
          client_quotation_id: quotation_id,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function create_quotation(request_id, product_id, reference) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "quotations",
        action: "create",
        data: {
          client_req_id: request_id,
          product_id: product_id,
          quotation_reference: reference,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function get_asset_requirements(product_id) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };

      const body = {
        resource: "product",
        action: "requirements",
        data: {
          product_id: product_id,
        },
      };
      console.log("the body issssss", body);
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function send_quotation(risk, benefits, client_quotation_id) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };

      const body = {
        resource: "quotations",
        action: "add_risk",
        data: {
          client_quotation_id: client_quotation_id,
          risk_details: {
            risk: risk,
            benefits: benefits,
          },
        },
      };
      console.log("THE BODY OF MAKING A QUOTE", body);

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function fetch_wallet_info() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "profile",
        action: "my_accounts",
        data: {},
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function fetch_commission_account() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "commision",
        action: "accounts",
        data: {},
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function get_transactions(account_number) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };

      const body = {
        resource: "account",
        action: "transactions",
        data: {
          account_number: account_number,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function fetch_my_underwriters() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "underwritter",
        action: "my_underwrittes",
        data: {},
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function fetch_claim_types(client_product_id) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "claim",
        action: "type",
        data: {
          client_product_id: client_product_id,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function fetch_poliy_claims(client_product_id) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "policies",
        action: "get_policy_claims",
        data: {
          client_product_id: client_product_id,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function view_policy(client_product_id) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "policies",
        action: "detail",
        data: {
          client_product_id: client_product_id,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function fetch_form(product_id, claim_code) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };

      const body = {
        resource: "claim",
        action: "form",
        data: {
          client_product_id: product_id,
          claim_type_code: claim_code,
          claim_category: "REPORT",
        },
      };
      console.log("the body to fetch form", body);
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function fetch_claim_form(product_id, claim_code) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };

      const body = {
        resource: "claim",
        action: "form",
        data: {
          client_product_id: product_id,
          claim_type_code: claim_code,
          claim_category: "CLAIM",
        },
      };
      console.log("the body to fetch form", body);
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function generate_claim_ref(client_product_id, client_id, claim_type) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "claim",
        action: "generate_claim_ref",
        data: {
          client_product_id: client_product_id,
          client_id: client_id,
          claim_category: "REPORT",
          claim_type: claim_type,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function report_claim(claimId, values) {
  return new Promise(async function (resolve, reject) {
    try {
      Object.entries(values).forEach(async ([key, value]) => {
        let config = {
          headers: {
            "Content-Type": "application/json",
            token: sessionStorage.getItem("token"),
          },
          // timeout:15000
        };

        const body = {
          resource: "claim",
          action: "make",
          data: {
            claim_id: claimId,
            field_code: `${key}`,
            value: `${value}`,
          },
        };

        let response = await call_api(body, config);
        return resolve(response);
      });
    } catch (err) {
      return reject(err);
    }
  });
}

function fetch_all_claims() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "claim",
        action: "list",
        data: {},
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function fetch_claim_details(claimId) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "claim",
        action: "details",
        data: {
          claim_id: claimId,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function fetch_claim_summary(claimId) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        action: "claim_summary",
        data: {
          claim_id: claimId,
        },
        resource: "claim",
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function make_claim_form(claimId) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "claim",
        action: "claimform",
        data: {
          claim_id: claimId,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function upload_individual_claim_field(claimId, values) {
  return new Promise(async function (resolve, reject) {
    try {
      Object.entries(values).forEach(async ([key, value]) => {
        let config = {
          headers: {
            "Content-Type": "application/json",
            token: sessionStorage.getItem("token"),
          },
          // timeout:15000
        };

        const body = {
          resource: "claim",
          action: "make",
          data: {
            claim_id: claimId,
            field_code: `${key}`,
            value: `${value}`,
          },
        };

        let response = await call_api(body, config);
        return resolve(response);
      });
    } catch (err) {
      console.log("we cant do this");
      return reject(err);
    }
  });
}

function send_claim(claimId, clientId) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "claim",
        action: "request",
        data: {
          claim_id: claimId,
          client_id: clientId,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function fetch_notifications() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "notifications",
        action: "list",
        data: {},
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function validate_reset_token(token) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "forgot_password",
        action: "validation",
        data: {
          email_token: token,
        },
      };
      console.log("the otp body is", body);

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function create_an_appointment({
  title,
  name,
  phone,
  description,
  time,
  date,
  email,
  underwriter,
}) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "intermediaryAppointment",
        action: "create_appointment",
        data: {
          appointment_date: date,
          appointment_time: time,
          appointment_subject: title,
          appointment_description: description,
          underwritter_id: parseInt(underwriter[0]?.value),
          client_details: {
            client_contact: phone,
            client_email: email,
            client_name: name,
          },
        },
      };

      console.log("body of appointment", body);

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function fetch_appointments() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "intermediaryAppointment",
        action: "list_intermediary_appointments",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function fetch_appointment_details(id) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "intermediaryAppointment",
        action: "get_appointment_details",
        data: {
          intermediary_appointment_id: id.queryKey[1],
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function close_appointment(id) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "intermediaryAppointment",
        action: "close_appointment",
        data: {
          intermediary_appointment_id: id,
          update: {
            appointment_closure_remarks: "Marked as Done",
          },
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function list_usmg_roles() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "role",
        action: "list",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function add_usmg_roles({ name, description, role_code }) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "role",
        action: "add",
        data: {
          name: name,
          description: description,
          role_code: role_code,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function list_system_resources() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "system_resource",
        action: "list",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function list_role_grants(role_code) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "role",
        action: "list_grants",
        data: {
          role_code: role_code,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function revoke_resource(resource_code, role_code) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "role",
        action: "revoke_resource",
        data: {
          resource_code: resource_code,
          role_code: role_code,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function grant_resource(role_code, resources) {
  return new Promise(async function (resolve, reject) {
    try {
      resources.map(async (item) => {
        let config = {
          headers: {
            "Content-Type": "application/json",
            token: sessionStorage.getItem("token"),
          },
          // timeout:15000
        };
        const body = {
          resource: "role",
          action: "grant_resource",
          data: {
            resource_code: item,
            role_code: role_code,
          },
        };

        let response = await call_api(body, config);
        return resolve(response);
      });
    } catch (err) {
      return reject(err);
    }
  });
}

function grant_permissions(grant_id, permissions) {
  return new Promise(async function (resolve, reject) {
    try {
      permissions.map(async (item) => {
        let config = {
          headers: {
            "Content-Type": "application/json",
            token: sessionStorage.getItem("token"),
          },
          // timeout:15000
        };
        const body = {
          resource: "role",
          action: "grant_permission",
          data: {
            grant_id: grant_id,
            permission: item,
          },
        };
        console.log("the body to send", body);

        let response = await call_api(body, config);
        return resolve(response);
      });
    } catch (err) {
      return reject(err);
    }
  });
}

function remove_permissions(grant_id, permission) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "role",
        action: "revoke_permission",
        data: {
          grant_id: grant_id,
          permission: permission,
        },
      };

      console.log("the body to send", body);

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function view_role_details(role_code) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "role",
        action: "get",
        data: {
          role_code: role_code,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function create_department({ name, code, description }) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "department",
        action: "create",
        data: {
          department_code: code,
          department_name: name,
          department_description: description,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function list_departments() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "department",
        action: "list",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function department_details(id) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "department",
        action: "get",
        data: {
          department_id: id,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function list_security_context() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "security_context",
        action: "list",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function list_users() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "user",
        action: "list",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function create_user({
  ref,
  name,
  department,
  role,
  contact,
  email,
  username,
  security,
}) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "user",
        action: "create",
        data: {
          employee_ref: ref,
          names: name,
          department: department,
          role_code: role,
          contact: contact,
          email: email,
          username: username,
          security_context_code: security,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function view_user(id) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "user",
        action: "get",
        data: {
          user_id: id,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function list_user_groups() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "user_group",
        action: "list",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function create_user_group({ code, name, description }) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "user_group",
        action: "create",
        data: {
          group_code: code,
          group_name: name,
          description: description,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function get_chat_groups() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "chat",
        action: "get_entity_chat_group",
        data: {
          entity: {
            entity_type: "INTERMEDIARY",
          },
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function create_chat_groups() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "chat",
        action: "create_chat_group",
        data: {},
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function get_user_details(group_id) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "user_group",
        action: "details",
        data: {
          group_id: group_id,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function add_user_to_group(group_id, user_id) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "user_group",
        action: "add_user",
        data: {
          group_id: group_id,
          user_id: user_id,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function add_chat_users_to_group(user_id) {
  return new Promise(async function (resolve, reject) {
    try {
      user_id.map(async (id) => {
        let config = {
          headers: {
            "Content-Type": "application/json",
            token: sessionStorage.getItem("token"),
          },
          // timeout:15000
        };
        const body = {
          resource: "chat",
          action: "add_user_chat_group",
          data: {
            user_id: Number(id),
          },
        };

        let response = await call_api(body, config);
        return resolve(response);
      });
    } catch (err) {
      return reject(err);
    }
  });
}

function create_context({
  name,
  password_policy,
  login_period_start,
  login_period_end,
  security_context_code,
  max_timeout,
  token_ttl,
  allowed_channels,
  browser_version,
  allowed_devices,
  device_version,
  two_factor_auth,
  holiday_login,
  description,
}) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "security_context",
        action: "create",
        data: {
          name: name,
          description: description,
          password_change_policy: password_policy,
          idle_max_timeout: max_timeout,
          token_ttl: token_ttl,
          security_context_code: security_context_code,
          login_context: {
            high: login_period_start,
            low: login_period_end,
          },
          allowed_channels: allowed_channels,
          allowed_devices: allowed_devices,
          device_versions: [
            {
              device_type: "and",
              device_version: "1",
            },
          ],
          two_factor_authentication: two_factor_auth,
          holiday_login: holiday_login,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function get_context(security_context_id) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "security_context",
        action: "get",
        data: {
          security_context_id: security_context_id,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function get_reviews() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "profile",
        action: "reviews",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

// CHAT
function chat_login() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "chat",
        action: "user_login",
        data: {},
      };
      let response = await call_api(body, config);
      sessionStorage.setItem("chat_id", response.chat_user_identification);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function get_conversations() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "chat",
        action: "my_conversations",
        data: {
          chat_user_id: parseInt(sessionStorage.getItem("chat_id")),
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function add_chat_conversation(id) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "chat",
        action: "get_chat_conversation_id",
        data: {
          from_chat_user_id: parseInt(sessionStorage.getItem("chat_id")),
          //  to_chat_user_id:parseInt(id),
          user_id: parseInt(id),
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function list_clients() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "policies",
        action: "list",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function chat_message_conversations(conversation_id) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "chat",
        action: "chat_conversations",
        data: {
          conversation_id: conversation_id,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

// MARKETING--------------
// Relationship Managers
function get_relationship_managers() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "rm",
        action: "list",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function assign_relationship_manager(selectedManager) {
  return new Promise(async function (resolve, reject) {
    try {
      selectedManager.map(async (manager) => {
        let config = {
          headers: {
            "Content-Type": "application/json",
            token: sessionStorage.getItem("token"),
          },
          // timeout:15000
        };
        const body = {
          resource: "rm",
          action: "create",
          data: {
            employee_id: String(manager.value),
            user_id: parseInt(manager.key),
          },
        };
        console.log("THE BODY OF RM", body);
        let response = await call_api(body, config);
        return resolve(response);
      });
    } catch (err) {
      return reject(err);
    }
  });
}

// Sales Rep
function get_sales_rep() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "sales_rep",
        action: "list",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function add_sales_rep({ name, email, phone }) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "sales_rep",
        action: "create",
        data: {
          sales_rep_names: name,
          sales_rep_email: email,
          contact: phone,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function get_rep_details(rep_ref) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };

      const body = {
        resource: "sales_rep",
        action: "sales_rep_details",
        data: {
          sales_rep_ref: rep_ref,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

// Leads
function list_leads() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "lead",
        action: "list",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function list_open_leads() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "lead",
        action: "list_status_open_leads",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function list_pending_claims() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "claim",
        action: "list_pending_approval",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function approve_pending_claims(remarks) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "claim",
        action: "claim_approval",
        data: {
          system_claim_ref: localStorage.getItem("system_ref"),
          intermediary_approval_details: {
            approval_type: "approve",
            approval_remarks: remarks,
            // "approval_document_path":"/test/test.pdf"
          },
        },
      };
      console.log("the body of approval", body);
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
// FEES AND TAXES

function list_fees() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "fees",
        action: "list_fees",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function add_fees({
  fee_name,
  fee_type,
  management,
  fee_reference,
  fee_description,
  taxed,
  entity_fee_account,
  entity_tax_ref,
  fee_value,
  remarks,
  slab,
}) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "fees",
        action: "add_fee",
        data: {
          fee_name: fee_name,
          fee_description: fee_description,
          fee_type: fee_type,
          fee_management: management,
          fee_value: fee_value,
          fee_slab: slab,
          entity_fee_ref: fee_reference,
          entity_fee_currency: "KES",
          tax_details: {
            is_taxed: taxed,
            taxes: entity_tax_ref.map((tax) => ({
              system_tax_ref: tax,
              is_inclusive: true,
            })),
          },
          entity_fee_account: entity_fee_account,
          remarks: remarks,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function getFeeDetails(entity_fee_ref) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "fees",
        action: "get",
        data: {
          entity_fee_ref: entity_fee_ref,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function calculateFee(values) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "fees",
        action: "calculate",
        data: {
          system_fee_ref: values.system_fee_ref,
          amount: values.amount,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function list_taxes() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "taxes",
        action: "list",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function list_tax_category() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "tax_category",
        action: "list",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function add_taxes({
  tax_name,
  tax_type,
  entity_tax_account,
  management,
  entity_tax_ref,
  tax_value,
  tax_description,
  tax_category,
  slab,
  remarks,
  systemTaxRef,
}) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "taxes",
        action: "add",
        data: {
          entity_tax_ref: entity_tax_ref,
          tax_name: tax_name,
          tax_description: tax_description,
          tax_type: tax_type,
          system_tax_category_ref: systemTaxRef,
          entity_tax_currency: "KES",
          entity_tax_account: entity_tax_account,
          tax_management: management,
          tax_value: tax_value,
          tax_category: tax_category,
          tax_slab: slab,
          remarks: remarks,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function calculateTax({ system_tax_ref, amount, inclusive }) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "taxes",
        action: "calculate",
        data: {
          system_tax_ref: system_tax_ref,
          amount: amount.value,
          option: inclusive.value,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function getTaxDetails(entity_tax_ref) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "taxes",
        action: "get",
        data: {
          entity_tax_ref: entity_tax_ref,
        },
      };
      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function rm_details(system_rm_ref) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "rm",
        action: "rm_details",
        data: {
          system_rm_ref: `rm/${system_rm_ref}`,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function lead_details(lead_ref) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "lead",
        action: "get",
        data: {
          lead_ref: `ld/${lead_ref}`,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function assign_rm_lead({ lead_ref, system_rm_ref }) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "lead",
        action: "assign_rm",
        data: {
          lead_ref: `ld/${lead_ref}`,
          system_rm_ref: system_rm_ref,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function assign_lead_sales_rep({ lead_ref, sales_rep_ref }) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "lead",
        action: "assign_lead_sales_rep",
        data: {
          lead_ref: `ld/${lead_ref}`,
          sales_rep_ref: sales_rep_ref,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function close_lead(data) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "lead",
        action: "close",
        data: {
          lead_ref: `ld/${data.lead_ref}`,
          business_ref: data.business_ref,
          closure_remarks: data.remarks,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function incentify_lead(data) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "lead",
        action: "lead_incentive",
        data: {
          lead_ref: `ld/${data.lead_ref}`,
          incentive: {
            incentive_amount: data.incentive_amount,
            incentive_currency: data.incentive_currency,
            incentive_remarks: data.incentive_remarks,
          },
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function create_lead_quotation(data) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "lead",
        action: "create_quotation",
        data: {
          lead_ref: `ld/${data.lead_ref}`,
          lead_quotation_ref: data.lead_quotation_ref,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function assign_open_lead(lead_ref) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "lead",
        action: "assign_open_lead_entity",
        data: {
          lead_ref: lead_ref,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function list_domains() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "entity_domain",
        action: "list",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function add_domains(domain) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "entity_domain",
        action: "add",
        data: {
          domain: domain.name,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

// settlement
function listSettlement() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "acc_settlement",
        action: "list",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function listConfigs() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "acc_settlement",
        action: "list_config",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function createConfig({
  intermediary_settlement_ref,
  name,
  description,
  account,
  type,
  frequency,
  frequency_day_of_week,
  frequency_day_of_month,
  frequency_remarks,
  your_ref,
  frequency_time,
  release_level,
  channel,
}) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "acc_settlement",
        action: "create_config",
        data: {
          stc_system_ref: channel,
          st_config_name: name,
          st_config_description: description,
          st_config_entity_ref: intermediary_settlement_ref,
          entity_account_number: account,
          st_config_type: type,
          st_config_release_level: release_level,
          st_config_frequency: {
            frequency_type: frequency,
            time_of_day: frequency_time,
            day_of_week: frequency_day_of_week,
            day_of_month: frequency_day_of_month,
          },
          st_remarks: frequency_remarks,
          st_reference: {
            reference_type: type,
            entity_ref: your_ref,
          },
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function listChannels() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "acc_settlement",
        action: "list_channel",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function createChannel() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "acc_settlement",
        action: "create_channel",
        data: {
          settlement_channel_currency: "KES",
          settlement_channel_name: "settlement test",
          settlement_channel_description: "describe...",
          tran_channel_code: "test",
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

// Schemes**************************************************
function listSchemes() {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "schemes",
        action: "list",
        data: {},
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function checkPin(scheme_pin) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "schemes",
        action: "check_scheme_pin_exists",
        data: {
          scheme_pin: scheme_pin,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function createScheme({
  scheme_name,
  scheme_ref,
  scheme_description,
  //   coordinates,
  scheme_details,
  scheme_pin,
  contact_person,
  scheme_admin_email,
  scheme_logo,
  underwriter_id,
}) {
  return new Promise(async function (resolve, reject) {
    try {
      let scheme_det = {};
      Array.isArray(scheme_details) &&
        scheme_details.map(({ key, value }) => {
          scheme_det = Object.assign(scheme_det, { [key]: value });
        });

      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "schemes",
        action: "create",
        data: {
          scheme_name: scheme_name,
          scheme_code: scheme_ref,
          scheme_description: scheme_description,
          coordinates: [38.455, 4.556],
          scheme_details: scheme_det,
          scheme_pin: scheme_pin,
          contact_person: [
            {
              name: "name name",
              email: "emal@email.com",
              contacts: "0700000000",
            },
          ],
          scheme_admin_email: scheme_admin_email,
          scheme_logo: scheme_logo,
          underwriter_id: underwriter_id,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

function schemeDetails(scheme_id) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "schemes",
        action: "get",
        data: {
          scheme_id: scheme_id,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function addSchemeDocument(values) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "schemes",
        action: "add_scheme_doc",
        data: {
          system_scheme_ref: values.scheme_ref,
          scheme_document: {
            doc_name: values.doc_name,
            doc_description: values.doc_description,
            doc_path: values.document,
          },
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function listSchemeDocuments(scheme_ref) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "schemes",
        action: "list_scheme_doc",
        data: {
          system_scheme_ref: scheme_ref,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function addSchemeGroup(values) {
  return new Promise(async function (resolve, reject) {
    try {
      let scheme_group_det = {};
      Array.isArray(values.scheme_group_detail) &&
        values.scheme_group_detail.map(({ key, value }) => {
          scheme_group_det = Object.assign(scheme_group_det, { [key]: value });
        });
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "schemes",
        action: "create_group",
        data: {
          scheme_group_name: values.scheme_group_name,
          scheme_group_description: values.scheme_group_description,
          scheme_group_detail: values.scheme_group_det,
          scheme_group_type: values.scheme_group_type,
          scheme_id: Number(values.scheme_id),
          policy_management_type: values.policy_management_type,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function listSchemeGroup(scheme_ref) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "schemes",
        action: "get_scheme_group",
        data: {
          system_scheme_ref: scheme_ref,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function schemeGroupDetails(system_scheme_group_ref) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "schemes",
        action: "get_group",
        data: {
          system_scheme_group_ref: `sch-grp/${system_scheme_group_ref}`,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function addSchemeGroupMember(values) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "schemes",
        action: "add_scheme_member",
        data: {
          scheme_id: Number(values.scheme_id),
          system_scheme_group_ref: values.system_scheme_group_ref,
          member_names: values.member_names,
          member_details: values.member_details,
          member_email: values.member_email,
          member_contact: values.member_contact,
          member_number: values.member_number,
          member_identification: {
            id_type: values.id_type,
            id_number: values.id_number,
          },
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function removeSchemeGroupMember(scheme_member_id) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "schemes",
        action: "remove_scheme_member",
        data: {
          scheme_member_id: scheme_member_id,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function listGroupMembers(system_scheme_group_ref) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "schemes",
        action: "list_group_members",
        data: {
          system_scheme_group_ref: system_scheme_group_ref,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function getMemberDetails(system_scheme_group_ref, system_scheme_member_ref) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "schemes",
        action: "get_group_scheme_member",
        data: {
          system_scheme_group_ref: system_scheme_group_ref,
          system_scheme_member_ref: system_scheme_member_ref,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function negotiateRate(values) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "schemes",
        action: "create_scheme_rate",
        data: {
          system_scheme_group_ref: `sch-grp/${values.system_scheme_group_ref}`,
          system_token_ref: values.system_token_ref,
          scheme_rate_name: values.scheme_rate_name,
          scheme_rate_description: values.scheme_rate_description,
          scheme_rate_ref: values.scheme_rate_ref,
          product_id: Number(values.product_id),
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}
function fetch_rates(system_scheme_group_ref) {
  return new Promise(async function (resolve, reject) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
        // timeout:15000
      };
      const body = {
        resource: "schemes",
        action: "get_group_scheme_products",
        data: {
          system_scheme_group_ref: `sch-grp/${system_scheme_group_ref}`,
        },
      };

      let response = await call_api(body, config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

// base functions

function call_api(body, config) {
  return new Promise(function (resolve, reject) {
    axios
      .post(api_url, body, config)
      .then(function (response) {
        return resolve(response.data.result);
      })
      .catch(function (error) {
        return reject(handle_api_error(error));
      });
  });
}

async function upload_api(upload_type, file) {
  return new Promise(async function (resolve, reject) {
    const fmData = new FormData();
    const uploadconfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        token: sessionStorage.getItem("token"),
      },
    };
    fmData.append(upload_type, file);
    axios
      .post(upload_url, fmData, uploadconfig)
      .then(function (response) {
        return resolve(response.data.result);
      })
      .catch(function (error) {
        return reject(handle_api_error(error));
      });
  });
}

export {
  api_url,
  base_url,
  register,
  login,
  verify,
  check_kyc,
  verify_email,
  forgot_password,
  get_intermediary_type,
  upload_company_logo,
  get_professions_list,
  submitCredentials,
  upload_individual_agent_kyc_details,
  upload_company_agent_kyc_details,
  upload_directors,
  get_requests,
  get_quotation_requests,
  get_dashboard_summary,
  get_professions,
  update_professions,
  call_api,
  upload_api,
  set_location,
  request_underwriter,
  get_underwriters_requests,
  get_subclass,
  get_subclass_products,
  get_asset_requirements,
  get_product_summary,
  view_quotation_request,
  get_all_quotations,
  create_quotation,
  view_a_quotation,
  send_quotation,
  fetch_wallet_info,
  fetch_my_underwriters,
  get_policies,
  get_transactions,
  fetch_claim_types,
  fetch_form,
  fetch_commission_account,
  view_policy,
  generate_claim_ref,
  fetch_all_claims,
  report_claim,
  fetch_claim_form,
  fetch_claim_details,
  fetch_poliy_claims,
  upload_individual_claim_field,
  make_claim_form,
  send_claim,
  fetch_notifications,
  validate_reset_token,
  reset,
  create_an_appointment,
  fetch_appointments,
  fetch_appointment_details,
  close_appointment,
  list_usmg_roles,
  add_usmg_roles,
  list_system_resources,
  list_role_grants,
  revoke_resource,
  grant_resource,
  grant_permissions,
  remove_permissions,
  view_role_details,
  create_department,
  list_departments,
  department_details,
  list_security_context,
  list_users,
  create_user,
  view_user,
  list_user_groups,
  create_user_group,
  get_user_details,
  add_user_to_group,
  create_context,
  get_context,
  get_reviews,
  chat_login,
  get_conversations,
  add_chat_conversation,
  list_clients,
  chat_message_conversations,
  get_chat_groups,
  create_chat_groups,
  add_chat_users_to_group,
  fetch_claim_summary,
  get_relationship_managers,
  get_sales_rep,
  assign_relationship_manager,
  add_sales_rep,
  get_rep_details,
  list_pending_claims,
  approve_pending_claims,
  list_fees,
  add_fees,
  list_taxes,
  add_taxes,
  list_leads,
  rm_details,
  lead_details,
  list_domains,
  add_domains,
  assign_rm_lead,
  assign_lead_sales_rep,
  close_lead,
  list_open_leads,
  incentify_lead,
  create_lead_quotation,
  assign_open_lead,
  listSettlement,
  listConfigs,
  createConfig,
  listChannels,
  createChannel,
  list_tax_category,
  getFeeDetails,
  calculateFee,
  getTaxDetails,
  calculateTax,
  accept_quotation_request,
  reject_quotation_request,
  fetch_risk_form,
  post_risk_form,
  list_recommendations,
  add_recommendations,
  list_risks,
  list_product_benefits,
  list_special_codes,
  recommendation_details,
  remove_recommendation,
  add_product_benefits,
  add_risk_to_recommendation,
  send_quotation_request,
  remove_product_benefits,
  listSchemes,
  checkPin,
  createScheme,
  schemeDetails,
  risk_details,
  addSchemeDocument,
  listSchemeDocuments,
  addSchemeGroup,
  listSchemeGroup,
  schemeGroupDetails,
  addSchemeGroupMember,
  removeSchemeGroupMember,
  listGroupMembers,
  getMemberDetails,
  negotiateRate,
  fetch_rates,
};
