import React, { useContext } from "react";

import { Modal } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";

import "../../../assets/css/dashboard.css";
import useForm from "shared/customHooks/useForm";
import { requestUnderwritterForm } from "components/widgets/forms/Request_Underwritter";
import AutoForm from "../forms/AutoForm";
import {
  request_underwriter,
  get_underwriters_requests,
} from "services/apiSrv";
import { ModalContext } from "context/ModalContext";
import { show_toast } from "utils/helpers";
import Loader from "components/widgets/loader/ComponentLoader";

const RequestUnderwriter = (props) => {
  const { modalClose } = useContext(ModalContext);

  async function getUnderwritters() {
    let resp = await get_underwriters_requests();
    console.log("underwritters", resp);
    return resp.underwritters;
  }

  const { data, isSuccess, isLoading } = useQuery(
    "fetch underwriters",
    getUnderwritters
  );
console.log("underwritters data", data)
  // console.log("THE OPTIONS", options);

  const makeRequest = useMutation(request_underwriter, {
    onSuccess: (data) => {
      console.log("THE DATA FROM USE MUTATION", data);
      modalClose();
     // props.refetch();
      show_toast("Request made successfully", "success");
    },
    onError: async (error) => {
      let err = await error;
      modalClose();
    },
  });

  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="text-grey">Request an Underwriter</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading && <Loader />}

          {isSuccess && (
            <AutoForm
              // config={requestUnderwritterForm.form.config}

              submitFn={makeRequest}
              formFn={requestUnderwritterForm}
              options={data}
              hasOptions={true}
              valueStr="code"
              labelStr="name"
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RequestUnderwriter;
