import React from "react";
import { fetch_wallet_info, get_transactions } from "services/apiSrv";
import { useQuery } from "react-query";
import Transactions from ".";
import Loader from "components/widgets/loader/ComponentLoader";

const TransactionHistoryWrapper = () => {
  const _accounts = async () => {
    let resp = await fetch_wallet_info();
    return resp.entity_accounts;
  };
  const _transactions = async ({ queryKey }) => {
    const [, account_number] = queryKey;
    let resp = await get_transactions(account_number);
    console.log("THE TRANSACTIONS", resp);
    return resp.transaction;
  };

  const { data: accounts } = useQuery("accounts", _accounts);
  // const account_number = accounts?.map((account, index) => {
  //   if (index === accounts.length - 1) {
  //     return account.account_number;
  //   }
  // });
  const account_number = accounts?.at(-1)?.account_number;
  const {
    isIdle,
    isLoading,
    isSuccess,
    data: transactions,
  } = useQuery(["transactions", account_number], _transactions, {
    enabled: !!account_number,
  });

  return (
    <>
      <Transactions
        isIdle={isIdle}
        isSuccess={isSuccess}
        isLoading={isLoading}
        transactions={transactions}
      />
    </>
  );
};

export default TransactionHistoryWrapper;
