import React, { memo, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import '../../../assets/css/dashboard.css';
import * as Icon from 'react-feather';

import Loader from 'components/widgets/loader/ComponentLoader';
import Moment from 'react-moment';

const ViewAppointment = (props) => {
	const { data, isLoading, isError, isSuccess } = props;

	console.log("appointment", data)
	return (
		<div>
			<Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" backdrop="static">
				<Modal.Header closeButton>
					<Modal.Title>
						<h5 className="text-grey">Appointment Details</h5>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{isLoading && <Loader />}
					{isError && <p>OOPS! Something went wrong!</p>}
					{isSuccess && (
						<>
							<div className="row">
								<div className="col-md-12 mb-3">
									<div className="row">
										<div className="col-md-1">
											<Icon.User size={20} />
										</div>
										<div className="col-md-11">{data.client_details.client_name}</div>
									</div>
								</div>
								<div className="col-md-12 mb-3">
									<div className="row">
										<div className="col-md-1">
											<Icon.Phone size={20} />
										</div>
										<div className="col-md-11">{data.client_details.client_contact}</div>
									</div>
								</div>
								<div className="col-md-12 mb-3">
									<div className="row">
										<div className="col-1">
											<Icon.Mail size={20} />
										</div>
										<div className="col-11">{data.client_details.client_email}</div>
									</div>
								</div>
								<div className="col-md-12 mb-3">
									<div className="row">
										<div className="col-1">
											<Icon.Briefcase size={20} />
										</div>
										<div className="col-11">Underwriter</div>
									</div>
								</div>
								<div className="col-md-12 mb-3">
									<div className="row">
										<div className="col-md-1">
											<Icon.Clock size={20} />
										</div>

										<div className="col-md-11">
											<Moment format="DD-MMM-YYYY">{data.appointment_date}</Moment>
											<span className="d-block">
												~ {''}
												{''}
												<Moment format="HH:MM:a">{data.appointment_date}</Moment>
											</span>
										</div>
									</div>
								</div>

								<div className="col-md-12 mb-3">
									<div className="row">
										<div className="col-md-1">
											<Icon.Map size={20} />
										</div>
										<div className="col-md-11">Appointment Location</div>
									</div>
								</div>
								<div className="col-md-12 mb-3">
									<div className="row">
										<div className="col-md-1">
											<Icon.FileText size={20} />
										</div>
										<div className="col-md-11">{data.appointment_description}</div>
									</div>
								</div>
							</div>
							<div className="d-flex justify-content-center">
								<button
									onClick={() => props.onHide()}
									color="primary"
									variant="contained"
									className="btn btn-outline-success px-5 my-4 "
								>
									Done
								</button>
							</div>
						</>
					)}
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default ViewAppointment;
